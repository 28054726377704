<template>
    <div>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            <strong>Cadastrar</strong>
        </button>  
        <br>
        <br>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form v-if="!this.mostraDREVersaoConfig && !this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição do demonstrativo de resultado" v-model="demonstracaoResultadoVersao.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Nome da Fórmula</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe o nome da fórmula do ativo" v-model="demonstracaoResultadoVersao.nome_formula">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentários</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Comente sobre esse demonstrativo de resultado" v-model="demonstracaoResultadoVersao.comentario">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Versão</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="demonstracaoResultadoCompartilhar.versao_plano_contas">
                                            <option v-for="versao_plano_contas in versoesPlanoContas" :key="versao_plano_contas.id" :value="versao_plano_contas">
                                                {{versao_plano_contas.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraDREVersaoConfig">

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição da linha da demonstração do resultado" v-model="demonstracaoResultadoVersaoConfig.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Sequencial</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" v-model="demonstracaoResultadoVersaoConfig.sequencial">
                                </div>
                            </div>
                        </div>


                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Negrito</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-normal">
                                    <select class="select is-fullwidth" v-model="demonstracaoResultadoVersaoConfig.eNegrito">
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </div>
                            </div>    
                        </div>    

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Desejada</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="demonstracaoResultadoVersaoConfig.tipo_evolucao_desejada">
                                        <option value="A">Alta</option>
                                        <option value="B">Baixa</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Referência AV</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="demonstracaoResultadoVersaoConfig.referencia_av">
                                        <option value="S">Sim</option>
                                        <option value="N">Não</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Fórmula</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Informe a fórmula para preenchimento" v-model="demonstracaoResultadoVersaoConfig.formula">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Demonstrações de Resultados</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><small>Descrição</small></th>
                                        <th><small>Comentários</small></th>
                                        <th colspan="3" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dre, index) in demonstrativosResultados" :key="dre.id">
                                        <td><small>{{dre.descricao}}</small></td>
                                        <td><small>{{dre.comentario}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" data-tooltip="Editar" @click="edit(index)" :disabled="mostraCompartilhamento || mostraDREVersaoConfig"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraCompartilhamento || mostraDREVersaoConfig"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Compartilhar" @click="callCompartilhamento(dre)" :disabled="mostraCompartilhamento || mostraDREVersaoConfig"><span class="fas fa-share-alt"></span></button></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="callDREConfig(dre)" :disabled="mostraCompartilhamento || mostraDREVersaoConfig"><span class="fas fa-th-list"></span></button></small></td>
                                    </tr>
                                </tbody>    

                            </table>
                        </div>    
                    </div>
                </div>    
            </div>

            <div class="column" v-show="this.mostraCompartilhamento">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Compartilhar {{ this.dreVersaoSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraCompartilhamento = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidth is-striped is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Versão</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dreVersao, index) in demonstracaoResultadoVersoes" :key="dreVersao.id">
                                        <td class="has-text-left"><small>{{dreVersao.versao_plano_contas.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>

                    <footer class="card-footer">
                        <a v-on:click="mostraCompartilhamento = false" class="card-footer-item">Fechar</a>
                    </footer>            

                </div>
            </div>

            <div class="column" v-show="this.mostraDREVersaoConfig">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Linhas da Demonstração de Resultado {{ this.dreVersaoSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraDREVersaoConfig = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidthtable is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Sequencial</small></th>
                                        <th class="has-text-left"><small>Descrição</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(linha, index) in linhasDemonstracaoResultado" :key="linha.id">
                                        <td class="has-text-left"><small>{{linha.sequencial}}</small></td>
                                        <td class="has-text-left"><small>{{linha.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>

                    <footer class="card-footer">
                        <a v-on:click="mostraDREVersaoConfig = false" class="card-footer-item">Fechar</a>
                    </footer>            

                </div>
            </div>
        </div>   
    </div>        

</template>

<script>
    // import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "DemonstracaoResultadoVersao",

        data(){
            return{
                demonstracaoResultadoVersao: {
                    id: null,
                    descricao: null,
                    comentario: null,
                    nome_formula: null,
                    versaoPlanoContas: null
                },
                demonstracaoResultadoCompartilhar: { id: null,
                    demonstracao_resultado_versao: null,
                    versao_plano_contas: null
                },
                demonstracaoResultadoVersaoConfig: {
                    id: null,
                    sequencial: null,
                    descricao: null,
                    eNegrito: "N",
                    indentacao: 0,
                    formula: null,
                    tipo_evolucao_desejada: "A",
                    referencia_av: "N"
                },
                versoesPlanoContas: [],
                demonstrativosResultados: [],
                linhasDemonstracaoResultado: [],
                demonstracaoResultadoVersoes: [],
                isShowModal: false,
                mostraDREVersaoConfig: false, 
                mostraCompartilhamento: false,
                dreVersaoSelecionado: {
                    id: null,
                    descricao: null
                },
                previous: null,
                next: null,
                page: null,
                numpages: null
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraDREVersaoConfig && !this.mostraCompartilhamento) {
                    this.demonstracaoResultadoVersao.id = this.demonstrativosResultados[index].id
                    this.demonstracaoResultadoVersao.descricao = this.demonstrativosResultados[index].descricao
                    this.demonstracaoResultadoVersao.nome_formula = this.demonstrativosResultados[index].nome_formula
                    this.demonstracaoResultadoVersao.comentario = this.demonstrativosResultados[index].comentario
                    this.demonstracaoResultadoVersao.index = index
                } 
                else {
                    this.demonstracaoResultadoVersaoConfig.id = this.linhasDemonstracaoResultado[index].id
                    this.demonstracaoResultadoVersaoConfig.descricao = this.linhasDemonstracaoResultado[index].descricao
                    this.demonstracaoResultadoVersaoConfig.sequencial = this.linhasDemonstracaoResultado[index].sequencial

                    if (this.linhasDemonstracaoResultado[index].e_negrito) {
                        this.demonstracaoResultadoVersaoConfig.eNegrito = "S"
                    } else {
                        this.demonstracaoResultadoVersaoConfig.eNegrito = "N"
                    }

                    this.demonstracaoResultadoVersaoConfig.indentacao = this.linhasDemonstracaoResultado[index].indentacao
                    this.demonstracaoResultadoVersaoConfig.formula = this.linhasDemonstracaoResultado[index].formula
                    this.demonstracaoResultadoVersaoConfig.demonstracaoResultadoVersao = this.dreVersaoSelecionado
                    this.demonstracaoResultadoVersaoConfig.tipo_evolucao_desejada = this.linhasDemonstracaoResultado[index].tipo_evolucao_desejada
                    this.demonstracaoResultadoVersaoConfig.referencia_av = this.linhasDemonstracaoResultado[index].referencia_av
                    this.demonstracaoResultadoVersaoConfig.index = index
                }
                this.isShowModal = true
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    if (!this.mostraDREVersaoConfig && !this.mostraCompartilhamento) {
                        http.delete('/demonstracaoResultadoVersao/excluir/'+this.demonstrativosResultados[index].id).then(response =>{
                            this.demonstrativosResultados.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else if (this.mostraCompartilhamento) {
                        http.delete('/demonstracaoResultadoVersao/excluirVersoes/'+this.demonstracaoResultadoVersoes[index].id).then(response =>{
                            this.demonstracaoResultadoVersoes.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                    else {
                        http.delete('/demonstracaoResultadoVersaoConfig/excluir/'+this.linhasDemonstracaoResultado[index].id).then(response =>{
                            this.linhasDemonstracaoResultado.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                }
            },

            callCompartilhamento: function(dreVersao) {
                this.mostraCompartilhamento = true
                this.dreVersaoSelecionado = dreVersao
                http.get('demonstracaoResultadoVersao/listarVersoes/', {params: {
                    idDemonstracaoResultadoVersao: this.dreVersaoSelecionado.id
                }}).then(response => {
                    this.demonstracaoResultadoVersoes = response.data.results
                })
            },

            clearFields: function() {
                if (!this.mostraDREVersaoConfig && !this.mostraCompartilhamento) {
                    this.demonstracaoResultadoVersao = { id: null,
                    descricao: null,
                    comentario: null,
                    nome_formula: null,
                    index: null }
                } else if (this.mostraCompartilhamento) {
                    this.demonstracaoResultadoCompartilhar = {id: null,
                        demonstracao_resultado_versao: null,
                        versao_plano_contas: null }
                } else {
                    this.demonstracaoResultadoVersaoConfig = {
                        id: null,
                        sequencial: null,
                        descricao: null,
                        eNegrito: "N",
                        indentacao: 0,
                        formula: null,
                        demonstracaoResultadoVersao: this.dreVersaoSelecionado,
                        tipo_evolucao_desejada: "A",
                        referencia_av: "N",
                        index: null
                    }
                }
            },

            callDREConfig: function(dreVersao) {
                this.mostraDREVersaoConfig = true
                this.dreVersaoSelecionado = dreVersao
                http.get('demonstracaoResultadoVersaoConfig/listar/', {params: {
                    idDemonstracaoResultadoVersao: this.dreVersaoSelecionado.id
                }}).then(response => {
                    this.linhasDemonstracaoResultado = response.data.results
                })

            },

            save: function(){
                var data

                if (!this.mostraDREVersaoConfig && !this.mostraCompartilhamento) {
                    if (this.demonstracaoResultadoVersao.descricao == null) {
                        alert('Descrição da demonstração do resultado não preenchida!')
                        return
                    }

                    if (this.demonstracaoResultadoVersao.nome_formula == null) {
                        alert('Nome da fórmula não preenchida!')
                        return
                    }

                    data = {id: this.demonstracaoResultadoVersao.id,
                        usuario: this.$store.state.idUser,
                        descricao: this.demonstracaoResultadoVersao.descricao,
                        nome_formula: this.demonstracaoResultadoVersao.nome_formula, 
                        comentario: this.demonstracaoResultadoVersao.comentario
                    }

                    let formData = new FormData()

                    formData.append('id', data.id)
                    formData.append('usuario', data.usuario)
                    formData.append('descricao', data.descricao)
                    formData.append('nome_formula', data.nome_formula)
                    formData.append('comentario', data.comentario)

                    if (this.demonstracaoResultadoVersao.id == null) {
                        http.post('/demonstracaoResultadoVersao/adicionar', formData).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.demonstracaoResultadoVersao.id = response.data.id
                                this.demonstrativosResultados.push(this.demonstracaoResultadoVersao)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })

                    } else {
                        http.put('/demonstracaoResultadoVersao/editar/'+this.demonstracaoResultadoVersao.id, formData).then(response =>{
                            this.demonstrativosResultados[this.demonstracaoResultadoVersao.index].descricao = response.data.descricao
                            this.demonstrativosResultados[this.demonstracaoResultadoVersao.index].nome_formula = this.demonstracaoResultadoVersao.nome_formula
                            this.demonstrativosResultados[this.demonstracaoResultadoVersao.index].comentario = this.demonstracaoResultadoVersao.comentario
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }
                } else if (this.mostraCompartilhamento) {
                    if (this.demonstracaoResultadoCompartilhar.versao_plano_contas == null) {
                        alert('Versão não informada!')
                        return
                    }

                    data = {id: this.demonstracaoResultadoCompartilhar.id,
                        demonstracao_resultado_versao: this.dreVersaoSelecionado.id,
                        versao_plano_contas: this.demonstracaoResultadoCompartilhar.versao_plano_contas.id
                    }

                    if (this.demonstracaoResultadoCompartilhar.id == null) {
                        http.post('/demonstracaoResultadoVersao/adicionarVersoes', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.demonstracaoResultadoCompartilhar.id = response.data.id
                                this.demonstracaoResultadoVersoes.push(this.demonstracaoResultadoCompartilhar)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                } else {
                    if (this.demonstracaoResultadoVersaoConfig.descricao == null) {
                        alert('Descrição da linha não informada!')
                        return
                    }

                    if (this.demonstracaoResultadoVersaoConfig.sequencial == null) {
                        alert('Sequencial da linha não informado!')
                        return
                    }

                    if (this.demonstracaoResultadoVersaoConfig.formula == null) {
                        alert('Fórmula da linha não informada!')
                        return
                    }

                    data = {id: this.demonstracaoResultadoVersaoConfig.id,
                        descricao: this.demonstracaoResultadoVersaoConfig.descricao,
                        sequencial: this.demonstracaoResultadoVersaoConfig.sequencial,
                        e_negrito: false,
                        indentacao: this.demonstracaoResultadoVersaoConfig.indentacao,
                        formula: this.demonstracaoResultadoVersaoConfig.formula,
                        tipo_evolucao_desejada: this.demonstracaoResultadoVersaoConfig.tipo_evolucao_desejada,
                        demonstracao_resultado_versao: this.dreVersaoSelecionado.id,
                        referencia_av: this.demonstracaoResultadoVersaoConfig.referencia_av
                    }

                    if (this.demonstracaoResultadoVersaoConfig.eNegrito == 'S') (
                        data.e_negrito = true
                    )

                    if (this.demonstracaoResultadoVersaoConfig.id == null) {
                        http.post('/demonstracaoResultadoVersaoConfig/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.demonstracaoResultadoVersaoConfig.id = response.data.id
                                this.linhasDemonstracaoResultado.push(this.demonstracaoResultadoVersaoConfig)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                    else {
                        http.put('/demonstracaoResultadoVersaoConfig/editar/'+this.demonstracaoResultadoVersaoConfig.id, data).then(response =>{
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].descricao = response.data.descricao
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].sequencial = response.data.sequencial
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].e_negrito = response.data.e_negrito
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].indentacao = response.data.indentacao
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].formula = response.data.formula
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].tipo_evolucao_desejada = response.data.tipo_evolucao_desejada
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].referencia_av = response.data.referencia_av
                            this.linhasDemonstracaoResultado[this.demonstracaoResultadoVersaoConfig.index].demonstracao_resultado_versao = this.dreVersaoSelecionado
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }                    
                }

            },
        },

        mounted: function() {
        },
        
        created: function() {
            http.get('versoesPlanoContas/listar').then(response => {
                this.versoesPlanoContas = response.data.results
            })

            http.get('demonstracaoResultadoVersao/listar/').then(response => {
                this.demonstrativosResultados = response.data.results
                this.previous = response.data.previous
                this.next = response.data.next
                this.page = response.data.page
                this.numpages = response.data.numpages
            })

        }

    }
</script>

<style scoped>
</style>