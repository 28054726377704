<template>

  <section class="section">
    <section class="hero">
      <div class="hero-body">
        <div class="columns">
          <div class="column is-four-fifths">
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <p class="title has-text-danger is-1">BOARD</p>
            <p class="subtitle is-2">Publicações financeiras com facilidade e agilidade</p>                
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>

          <div class="column"></div>
          <div class="column"></div>
        </div>
      </div>
    </section>

    <section class="hero">
      <div class="has-text-centered">
        <figure class="image">
          <img src="../../assets/img/board02-original.webp" />
        </figure>              
      </div>
    </section>

    <br>

    <section class="hero has-background-light">
      <div class="hero-body">
        <div class="columns">
          <div class="column has-text-centered">
            <div class="has-text-black has-background-light-90 is-size-1 has-text-weight-bold">
              <br>
              <p class="title is-1 has-text-centered">Uma forma simples<br/> de entregar informações <br/> econômico-financeiras indispensáveis<br> para a gestão empresarial</p>
              <br>
              <br>
            </div>				
          </div>
        </div>
      </div>
    </section>

    <br>

    <section class="hero' has-background-light">
      <div class="hero-body">
        <div class="columns">
          <div class="column content">
            
            <div class="has-text-black has-background-light-90 is-size-1 has-text-weight-bold">
              <p class="title is-1 has-text-centered">Benefícios</p>
              <br>

              <div class="columns">
                <div class="column">
                  <ul class="subtitle is-3 has-text-primary-60">
                    <li>Garantir acesso ágil e confiável a informações econômico-financeiras de alta qualidade para embasar decisões estratégicas</li>
                    <li>Automatizar e otimizar a criação de publicações financeiras</li>
                  </ul>  
                </div>

                <div class="column">
                  <ul class="subtitle is-3 has-text-link-80">
                    <li>Gerar demonstrações financeiras personalizadas sob demanda</li>
                    <li>Fornecer indicadores gerenciais estratégicos</li>
                  </ul>  
                </div>
                <div class="column">
                  <ul class="subtitle is-3 has-text-danger-70">
                    <li>Adaptar-se a diferentes períodos de análise: mensal, bimestral, trimestral, quadrimestral, semestral, anual e últimos 12 meses</li>
                    <li>Automatizar o envio de relatórios em formatos PDF e Excel por e-mail</li>
                  </ul>  
                </div>

                <div class="column">
                  <ul class="subtitle is-3 has-text-warning">
                    <li>Gerar receita como serviço adicional</li>
                    <li>Permitir múltiplos perfis de clientes</li>
                  </ul>  
                </div>

              </div>                
            </div>				
          </div>
        </div>
      </div>
    </section>

    <br>

    <section class="hero has-background-light">
      <div class="hero-body">

        <div class="columns">
            <div class="column is-half">
              <br>
            <div class="has-text-black has-background-light is-size-3">Crie balanços patrimoniais e demonstrativos de resultados de acordo com a sua necessidade. O <span class="has-text-danger has-text-weight-bold">BOARD</span> disponibiliza modelos de publicações para que você possa aplicar rapidamente e surpreender seus clientes
              <br>
            </div>				
          </div>
          <div class="column">
            <br>
            <br>
            <br>
            <p class="title is-1 has-text-right"><span class="fas fa-file-invoice-dollar has-text-success"></span><br/>Demonstrações<br/>Financeiras</p>
          </div>
        </div>
      </div>
    </section>

    <section class="hero has-background-light">
      <div class="hero-body">

        <div class="columns">
            <div class="column is-half">
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <p class="title is-1 has-text-left"><span class="fas fa-chart-line has-text-info"></span><br/>Indicadores<br/>Gerenciais</p>
          </div>
          <div class="column">
            <br>
            <div class="has-text-black has-background-light is-size-3">As publicações financeiras são fontes espetaculares de informações 
                gerenciais.<br> O <span class="has-text-danger has-text-weight-bold">BOARD</span> fornece diversos indicadores econômico-financeiros extraídos destas publicações.
                Você tambem pode criar seus indicadores. Qualquer informação do seu balancete, balanço patrimonial e DRE podem ser extraídos 
                com uma simples fórmula.
                <br>
              </div>				
          </div>
        </div>
      </div>
    </section>

    <section class="hero has-background-light">
      <div class="hero-body">

        <div class="columns">
            <div class="column is-half">
              <br>
            <div class="has-text-black has-background-light is-size-3">Visualize a evolução das informações econômico-financeiras de forma sofisticada e simples. Surpreenda seus clientes com um caderno fantástico contemplando as publicações financeiras e todos os indicadores gerenciais.
              <br>
            </div>				
          </div>
          <div class="column">
            <br>
            <br>
            <br>
            <br>
            <p class="title is-1 has-text-right"><span class="fas fa-tv has-text-primary"></span><br/> Apresentação</p>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="hero is-small is-black">
      <div class="hero-body">
        <div class="columns">
          <div class="column">
            <br>
            <br>
            <div class="has-text-white has-background-black is-size-2 has-text-weight-bold">Modele, Invista e acompanhe a evolução do seu patrimônio.
              <br>
              <br>
            </div>				
          </div>

          <div class="column is-half has-text-right">
            <br>
            <br>
            <br>
            <p class="title has-text-warning is-1">QUIVER</p>
            <p class="subtitle has-text-white">Gestão de carteiras de investimentos</p>
          </div>



        </div>
      </div>
    </section>

    <br>

    <section class="hero is-small has-background-grey-lighter">
      <div class="hero-body">
        <div class="columns">
          <div class="column is-half">
            <br>
            <br>
            <br>
            <br>
            <p class="title has-text-success is-1">PRO MODEL</p>
            <p class="subtitle has-text-black">Simulação de negócios (em breve)</p>
          </div>
          <div class="column">
            <br>
            <div class="has-text-black has-background-grey-lighter is-size-2 has-text-weight-bold">Simule e veja os impactos financeiros das tomadas de decisões no resultado.
              <br>
              <br>
            </div>				
          </div>
        </div>
      </div>
    </section> -->

    <br>
    <br>

    <footer class="footer has-background-link">
      <div class="content">
        <div class="columns">
          <div class="column is-four-fifths"></div>
          <div colspan="3" class="column has-text-centered has-text-white">Contatos</div>
        </div>
        <div class="columns has-text-white">
          <div class="column is-four-fifths"><strong class="has-text-white">LineApps</strong> | Soluções alinhadas ao sua necessidade | versão: 202501-01</div>
          <div class="column is-size-3 has-text-centered has-text-white"><strong class="has-text-white"><small><a class="has-text-white fab fa-whatsapp" href="https://wa.me/5581994032445" target="_blank"></a></small></strong></div>
          <div class="column is-size-3 has-text-centered has-text-white"><strong class="has-text-white"><small><a class="has-text-white fa fa-at" href="mailto:lineapps@pgpaf.com.br" target="_blank"></a></small></strong></div>
          <div class="column is-size-3 has-text-centered has-text-white"><strong class="has-text-white"><small><a class="has-text-white fab fa-linkedin" href="https://www.linkedin.com/groups/13163643/" target="_blank"></a></small></strong></div>
        </div>
      </div>
    </footer>

  </section>

</template>

<script>
    export default {
        name: "MenuPrincipal",

        methods: {
        },

        mounted: function() {
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>