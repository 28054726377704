<template>
    <div>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            <strong>Cadastrar</strong>
        </button>  
        <br>
        <br>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form v-if="!this.mostraIndicadores && !this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição do balanco patrimonial" v-model="grupoIndicador.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentários</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Comente sobre esse balanço patrimonial" v-model="grupoIndicador.comentario">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Dashboard?</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <select class="select is-fullwidth" v-model="grupoIndicador.dashboard">
                                        <option value="1">Sim</option>
                                        <option value="2">Não</option>
                                    </select>
                                </div>   
                            </div>
                        </div>

                    </form>  

                    <form v-if="this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Versão</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="grupoIndicadorCompartilhar.versao_plano_contas">
                                            <option v-for="versao_plano_contas in versoesPlanoContas" :key="versao_plano_contas.id" :value="versao_plano_contas">
                                                {{versao_plano_contas.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraIndicadores">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição da versão do plano de contas" v-model="indicador.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Índice</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" v-model="indicador.indice">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Desejada</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field">
                                    <select class="select is-fullwidth" v-model="indicador.tipo_evolucao_desejada">
                                        <option value="A">Alta</option>
                                        <option value="B">Baixa</option>
                                    </select>
                                </div>   
                            </div>    
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Fórmula</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Informe a fórmula para preenchimento" v-model="indicador.formula">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentário</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Informe um comentário" v-model="indicador.comentario">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Fator de apresentação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" v-model="indicador.fator_apresentacao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Dashboard?</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <select class="select is-fullwidth" v-model="indicador.dashboard" :disabled="indicador.grupo_indicador.dashboard == false">
                                        <option value="1">Sim</option>
                                        <option value="2">Não</option>
                                    </select>
                                </div>   
                            </div>
                        </div>

                    </form>  

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="save()">Salvar</button>
                    <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Grupos de Indicadores</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><small>Descrição</small></th>
                                        <th><small>Comentários</small></th>
                                        <th colspan="4" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(gi, index) in gruposIndicadores" :key="gi.id">
                                        <td><small>{{gi.descricao}}</small></td>
                                        <td><small>{{gi.comentario}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" :disabled="mostraCompartilhamento || mostraIndicadores"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraCompartilhamento || mostraIndicadores"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Compartilhar" @click="callCompartilhamento(gi)" :disabled="mostraCompartilhamento || mostraIndicadores"><span class="fas fa-share-alt"></span></button></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="callIndicador(gi)" :disabled="mostraCompartilhamento || mostraIndicadores"><span class="fas fa-th-list"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="column" v-show="this.mostraCompartilhamento">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Compartilhar {{ this.grupoIndicadorSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraCompartilhamento = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidth is-striped is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Versão</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(gi, index) in grupoIndicadorVersoes" :key="gi.id">
                                        <td class="has-text-left"><small>{{gi.versao_plano_contas.descricao}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>

                    <footer class="card-footer">
                        <a v-on:click="mostraCompartilhamento = false" class="card-footer-item">Fechar</a>
                    </footer>            

                </div>
            </div>

            <div class="column" v-show="this.mostraIndicadores">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Indicadores do Grupo {{ this.grupoIndicadorSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraIndicadores = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidth is-striped is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Índice</small></th>
                                        <th class="has-text-left"><small>Indicador</small></th>
                                        <th class="has-text-left"><small>Comentário</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ind, index) in indicadores" :key="ind.id">
                                        <td class="has-text-left"><small>{{ind.indice}}</small></td>
                                        <td class="has-text-left"><small>{{ind.descricao}}</small></td>
                                        <td class="has-text-left"><small>{{ind.comentario}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>

                    <footer class="card-footer">
                        <a v-on:click="mostraIndicadores = false" class="card-footer-item">Fechar</a>
                    </footer>            

                </div>
            </div>
        </div>   
    </div>        

</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "GrupoIndicador",

        data(){
            return{
                grupoIndicador: {
                    id: null,
                    descricao: null,
                    comentario: null,
                    usuario: null,
                    dashboard: "1"
                },
                grupoIndicadorCompartilhar: { id: null,
                    grupo_indicador: null,
                    versao_plano_contas: null
                },
                indicador: {
                    id: null,
                    grupo_indicador: {
                        id: null,
                        descricao: null,
                        comentario: null,
                        usuario: null,
                        dashboard: "1"
                    },
                    usuario: null,
                    descricao: null,
                    indice: "N",
                    formula: null,
                    comentario: null,
                    tipo_evolucao_desejada: null,
                    fator_apresentacao: 1,
                    dashboard: "1"
                },
                versoesPlanoContas: [],
                perfisUsuario: [],
                gruposIndicadores: [],
                grupoIndicadorVersoes: [],
                indicadores: [],
                isShowModal: false,
                mostraIndicadores: false, 
                mostraCompartilhamento: false,
                grupoIndicadorSelecionado: {
                    id: null,
                    descricao: null,
                    dashboard: null
                },
                previous: null,
                next: null,
                page: null,
                numpages: null }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraIndicadores && !this.mostraCompartilhamento) {
                    this.grupoIndicador.id = this.gruposIndicadores[index].id
                    this.grupoIndicador.descricao = this.gruposIndicadores[index].descricao
                    this.grupoIndicador.comentario = this.gruposIndicadores[index].comentario

                    if (this.gruposIndicadores[index].dashboard) {
                        this.grupoIndicador.dashboard = "1"
                    } else {
                        this.grupoIndicador.dashboard = "2"
                    }

                    this.grupoIndicador.index = index
                } 
                else {
                    this.indicador.id = this.indicadores[index].id
                    this.indicador.grupo_indicador = this.grupoIndicadorSelecionado
                    this.indicador.usuario = this.$store.state.idUser
                    this.indicador.descricao = this.indicadores[index].descricao
                    this.indicador.indice = this.indicadores[index].indice
                    this.indicador.formula = this.indicadores[index].formula
                    this.indicador.comentario = this.indicadores[index].comentario
                    this.indicador.tipo_evolucao_desejada = this.indicadores[index].tipo_evolucao_desejada
                    this.indicador.fator_apresentacao = this.indicadores[index].fator_apresentacao

                    if (this.indicadores[index].dashboard) {
                        this.indicador.dashboard = "1"
                    } else {
                        this.indicador.dashboard = "2"
                    }

                    this.indicador.index = index

                }
                this.isShowModal = true
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    if (!this.mostraIndicadores && !this.mostraCompartilhamento) {
                        http.delete('/grupoIndicador/excluir/'+this.gruposIndicadores[index].id).then(response =>{
                            this.gruposIndicadores.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else if (this.mostraCompartilhamento) {
                        http.delete('/grupoIndicador/excluirVersoes/'+this.grupoIndicadorVersoes[index].id).then(response =>{
                            this.grupoIndicadorVersoes.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                    else {
                        http.delete('/indicador/excluir/'+this.indicadores[index].id).then(response =>{
                            this.indicadores.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                }
            },

            callCompartilhamento: function(grpIndicador) {
                this.mostraCompartilhamento = true
                this.grupoIndicadorSelecionado = grpIndicador
                http.get('grupoIndicador/listarVersoes/', {params: {
                    idGrupoIndicador: this.grupoIndicadorSelecionado.id
                }}).then(response => {
                    this.grupoIndicadorVersoes = response.data.results
                })
            },

            clearFields: function() {
                if (!this.mostraIndicadores && !this.mostraCompartilhamento) {
                    this.grupoIndicador = { id: null,
                    descricao: null,
                    comentario: null,
                    usuario: null,
                    dashboard: "1",
                    index: null }
                } else if (this.mostraCompartilhamento) {
                    this.grupoIndicadorCompartilhar = {id: null,
                        grupo_indicador: null,
                        versao_plano_contas: null }
                } else {
                    this.indicador = {
                        id: null,
                        usuario: this.$store.state.idUser,
                        descricao: null,
                        indice: null,
                        formula: null,
                        comentario: null,
                        grupo_indicador: this.grupoIndicadorSelecionado,
                        tipo_evolucao_desejada: null,
                        fator_apresentacao: 1,
                        dashboard: this.grupoIndicadorSelecionado.dashboard,
                        index: null
                    }
                    
                    if (!this.indicador.dashboard) {
                        this.indicador.dashboard = "2"
                    } else {
                        this.indicador.dashboard = "1"
                    }
                }
            },

            callIndicador: function(grpIndicador) {
                this.mostraIndicadores = true
                this.grupoIndicadorSelecionado = grpIndicador
                http.get('indicador/listar/', {params: {
                    idGrupoIndicador: this.grupoIndicadorSelecionado.id}}).then(response => {
                    this.indicadores = response.data.results
                })
            },

            save: function(){
                var data

                if (!this.mostraIndicadores && !this.mostraCompartilhamento) {
                    if (this.grupoIndicador.descricao == null) {
                        alert('Descrição do grupo de indicadores não preenchida!')
                        return
                    }

                    data = {id: this.grupoIndicador.id,
                        usuario: this.$store.state.idUser,
                        descricao: this.grupoIndicador.descricao,
                        comentario: this.grupoIndicador.comentario,
                        dashboard: true
                    }

                    if (this.grupoIndicador.dashboard == "1") {
                        data.dashboard = true
                    } else {
                        data.dashboard = false
                    }

                    let formData = new FormData()

                    formData.append('id', data.id)
                    formData.append('usuario', data.usuario)
                    formData.append('descricao', data.descricao)
                    formData.append('comentario', data.comentario)
                    formData.append('dashboard', data.dashboard)

                    if (this.grupoIndicador.id == null) {
                        http.post('/grupoIndicador/adicionar', formData).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.grupoIndicador.id = response.data.id
                                this.gruposIndicadores.push(this.grupoIndicador)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })

                    } 
                    else {
                        http.put('/grupoIndicador/editar/'+this.grupoIndicador.id, formData).then(response =>{
                            this.gruposIndicadores[this.grupoIndicador.index].descricao = response.data.descricao
                            this.gruposIndicadores[this.grupoIndicador.index].comentario = this.grupoIndicador.comentario
                            if (this.grupoIndicador.dashboard == "1") {
                                this.gruposIndicadores[this.grupoIndicador.index].dashboard = true
                            } else {
                                this.gruposIndicadores[this.grupoIndicador.index].dashboard = false
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                } else if (this.mostraCompartilhamento) {
                    if (this.grupoIndicadorCompartilhar.versao_plano_contas == null) {
                        alert('Versão não informada!')
                        return
                    }

                    data = {id: this.grupoIndicadorCompartilhar.id,
                        grupo_indicador: this.grupoIndicadorSelecionado.id,
                        versao_plano_contas: this.grupoIndicadorCompartilhar.versao_plano_contas.id
                    }

                    if (this.grupoIndicadorCompartilhar.id == null) {
                        http.post('/grupoIndicador/adicionarVersoes', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.grupoIndicadorCompartilhar.id = response.data.id
                                this.grupoIndicadorVersoes.push(this.grupoIndicadorCompartilhar)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                } else {
                    if (this.indicador.descricao == null) {
                        alert('Descrição da linha não informada!')
                        return
                    }

                    if (this.indicador.indice == null) {
                        alert('Índice não informado!')
                        return
                    }

                    if (this.indicador.formula == null) {
                        alert('Fórmula do indicador não informada!')
                        return
                    }

                    if (this.indicador.fator_apresentacao == null || this.indicador.fator_apresentacao == 0 || this.indicador.fator_apresentacao == "") {
                        alert('Fator de apresentação não informado!')
                        return
                    }

                    data = {id: this.indicador.id,
                        grupo_indicador: this.grupoIndicadorSelecionado.id,
                        usuario: this.$store.state.idUser,
                        descricao: this.indicador.descricao,
                        indice: this.indicador.indice,
                        formula: this.indicador.formula,
                        comentario: this.indicador.comentario,
                        tipo_evolucao_desejada: this.indicador.tipo_evolucao_desejada,
                        fator_apresentacao: this.indicador.fator_apresentacao,
                        dashboard: true
                    }

                    if (this.indicador.dashboard == "1") {
                        data.dashboard = true
                    } else {
                        data.dashboard = false
                    }

                    // Tratar erro constratint da UK

                    if (this.indicador.id == null) {
                        http.post('/indicador/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.indicador.id = response.data.id
                                this.indicadores.push(this.indicador)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    } 
                    else {
                        http.put('/indicador/editar/'+this.indicador.id, data).then(response =>{
                            this.indicadores[this.indicador.index].descricao = response.data.descricao
                            this.indicadores[this.indicador.index].indice = response.data.indice
                            this.indicadores[this.indicador.index].formula = response.data.formula
                            this.indicadores[this.indicador.index].fator_apresentacao = response.data.fator_apresentacao
                            this.indicadores[this.indicador.index].comentario = response.data.comentario
                            this.indicadores[this.indicador.index].tipo_evolucao_desejada = response.data.tipo_evolucao_desejada
                            this.indicadores[this.indicador.index].grupo_indicador = this.grupoIndicadorSelecionado

                            if (this.indicador.dashboard == "1") {
                                this.indicadores[this.indicador.index].dashboard = true
                            } else {
                                this.indicadores[this.indicador.index].dashboard = false
                            }

                            this.indicadores[this.indicador.index].usuario = this.$store.state.idUser
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }                    
                }

            },
        },

        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                this.perfisUsuario = response.data.results
            })

            http.get('versoesPlanoContas/listar').then(response => {
                this.versoesPlanoContas = response.data.results
            })

            http.get('grupoIndicador/listar/').then(response => {
                this.gruposIndicadores = response.data.results
                this.previous = response.data.previous
                this.next = response.data.next
                this.page = response.data.page
                this.numpages = response.data.numpages
            })
        }    
    }
</script>

<style scoped>
</style>