import Vue from 'vue'
import Vuex from 'vuex'
// import VuexPersistence from 'vuex-persist'
import VueRouter from 'vue-router'

import App from './App.vue'

// Common
import MenuPrincipal from './components/common/MenuPrincipal.vue'

// Investmethod
import Empresa from './components/investments/Empresa.vue'
import Benchmark from './components/investments/Benchmark.vue'
import Ativo from './components/investments/Ativo.vue'
import CorretoraSelecao from './components/investments/CorretoraSelecao.vue'
import CorretoraExtrato from './components/investments/CorretoraExtrato.vue'
import NotaNegociacao from './components/investments/NotaNegociacao.vue'
import Periodo from './components/investments/Periodo.vue'
import Dashboard from './components/investments/Dashboard.vue'
import MapaProventos from './components/investments/MapaProventos.vue'
import ModelagemCarteira from './components/investments/ModelagemCarteira.vue'
import ModeloCarteiraAporte from './components/investments/ModeloCarteiraAporte.vue'

// Accounting
import Perfil from './components/accounting/Perfil.vue'
import PlanoDeContas from './components/accounting/PlanoDeContas.vue'
import PeriodoPerfil from './components/accounting/PeriodoPerfil.vue'
import Balancete from './components/accounting/Balancete.vue'
import BalancoPatrimonialVersao from './components/accounting/BalancoPatrimonialVersao.vue'
import DemonstracaoResultadoVersao from './components/accounting/DemonstracaoResultadoVersao.vue'
import BalancoPatrimonialPeriodo from './components/accounting/BalancoPatrimonialPeriodo.vue'
import DemonstracaoResultadoPeriodo from './components/accounting/DemonstracaoResultadoPeriodo.vue'
import Indicador from './components/accounting/Indicador.vue'
import IndicadorPeriodo from './components/accounting/IndicadorPeriodo.vue'
import ValorReferenciaIndicador from './components/accounting/ValorReferenciaIndicador.vue'
import OutrasInformacoesVersao from './components/accounting/OutrasInformacoesVersao.vue'
import OutrasInformacoesPeriodo from './components/accounting/OutrasInformacoesPeriodo.vue'
import Grafico from './components/accounting/Grafico.vue'

import '../node_modules/bulma/css/bulma.css'
import '../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css'

import moment from 'moment'

import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false

//Filtros Globais
Vue.filter('dateMMYYYY', function(value){
  if (!value) return ''
  return moment(value).format('MM/YYYY')
})

Vue.filter('dateDDMMYYYY', function(value){
  if (!value) return ''
  return moment(value).format('DD/MM/YYYY')
})

Vue.filter('datePorTipo', function(value, tipo){
  if (!value) return ''
  if (tipo == 6) {
    return moment(value).format('YYYY')
  } else {
    return moment(value).format('MM/YYYY')
  }
})


Vue.filter('numberFormat', function(value) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2
  })  
  
  if (!value) return ''

  return formatter.format(value)
})

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: MenuPrincipal
    },
    {
      path: '/inicio',
      component: MenuPrincipal
    },
    {
      path: '/empresa',
      component: Empresa
    },
    {
      path: '/benchmark',
      component: Benchmark
    },
    {
      path: '/ativo',
      component: Ativo
    },
    {
      path: '/corretoraSelecao',
      component: CorretoraSelecao
    },
    {
      path: '/corretoraExtrato',
      component: CorretoraExtrato
    },
    {
      path: '/notaNegociacao',
      component: NotaNegociacao
    },
    {
      path: '/periodo',
      component: Periodo
    },
    {
      path: '/dashboard',
      component: Dashboard
    },
    {
      path: '/mapaProventos',
      component: MapaProventos
    },
    {
      path: '/mapaProventos',
      component: MapaProventos
    },
    {
      path: '/modelagemCarteira',
      component: ModelagemCarteira
    },
    {
      path: '/aportes',
      component: ModeloCarteiraAporte
    },
    {
      path: '/perfil',
      component: Perfil
    },
    {
      path: '/planoDeContas',
      component: PlanoDeContas
    },
    {
      path: '/periodoPerfil',
      component: PeriodoPerfil
    },
    {
      path: '/balancete',
      component: Balancete
    },
    {
      path: '/bpVersao',
      component: BalancoPatrimonialVersao
    },
    {
      path: '/dreVersao',
      component: DemonstracaoResultadoVersao
    },
    {
      path: '/bpPeriodo',
      component: BalancoPatrimonialPeriodo
    },
    {
      path: '/drePeriodo',
      component: DemonstracaoResultadoPeriodo
    },
    {
      path: '/indicador',
      component: Indicador
    },
    {
      path: '/indicadorPeriodo',
      component: IndicadorPeriodo
    },
    {
      path: '/valorReferenciaIndicador',
      component: ValorReferenciaIndicador
    },
    {
      path: '/outrasInformacoesVersao',
      component: OutrasInformacoesVersao
    },
    {
      path: '/outrasInformacoesPeriodo',
      component: OutrasInformacoesPeriodo
    },
    {
      path: '/grafico',
      component: Grafico
    }
  ]
})

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

// const vuexLocal = new VuexPersistence({
//   key: 'vuex',
//   storage: window.localStorage
// })

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: null,
    idUser: null,
    selectedModule: null,
  },
  mutations: {
    // setToken( state, token ){
    //   state.token = token
    // },
    // setUsername( state, username ){
    //   state.idUser = username
    // }
  },

  // plugins: [vuexLocal.plugin]

  // plugins: [
  //   new VuexPersistence({
  //     storage: window.localStorage
  //   }).plugin
  // ]
})

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')

// export default store